<template>
  <div class="single-podcasts-wrap">
    <div
      v-for="(stream, i) in sortedCourseData.streams"
      :key="`podcast${i}`"
      class="single-podcast-wrap"
      :class="{ 'private-podcast': isAudioAvailable(stream)}"
    >
      <SinglePodcast
        :class="{'podcast-opacity': !isAudioAvailable(stream)}"
        :podcast-data="stream"
        :course-media="courseData.media"
        @click="openPlayer(stream, courseData, TRACKING_PUBLIC_PODCAST_VIEW)"
      />
      <p
        v-if="!isAudioAvailable(stream)"
        class="no-access-text"
      >
        {{ $t('commonKeys.noAccess') }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import cloneDeep from "lodash/cloneDeep";
import { useStore } from "vuex";
import { TRACKING_PUBLIC_PODCAST_VIEW } from "@/config/constants";
import SinglePodcast from "@/components/common/SinglePodcast";

export default {
  name: "PublicPodcastStreamList",
  components: {SinglePodcast},
  setup(){
    const store = useStore();
    const courseData = computed(() => store.getters['course/getCourseData']);

    const assignValue = (stream) => {
      if (isAudioAvailable(stream)) {
        return 1;
      }
      return 0;
    };

    const sortedCourseData = computed(() => {
      const data = cloneDeep(courseData.value);
      data.streams.sort((a, b) => assignValue(b) - assignValue(a));
      return data;
    });

    const isAudioAvailable = (stream) => {
      const hasAudioFile = stream.media.some(el => el.media_type === 'audio');
      return Boolean(hasAudioFile || stream.embed_url);
    };


    const openPlayer = (stream, course, tracking) => {
      let startAt = course.streams.findIndex(companyStream => companyStream.id === stream.id);
      if (startAt < 0) {
        startAt = 0;
      }
      store.dispatch('player/openPlayer', {
        course,
        company: course.company,
        playlist: course.streams,
        startAt,
        tracking,
      });
    };

    return {
      openPlayer,
      isAudioAvailable,
      courseData,
      sortedCourseData,
      TRACKING_PUBLIC_PODCAST_VIEW,
    };
  },
};
</script>

<style scoped lang="scss">
  .single-podcast-wrap {
    position: relative;

  .no-access-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .podcast-opacity {
    opacity: 0.2;
    cursor: unset;
    pointer-events: none;
  }
  }
  .single-podcasts-wrap .single-podcast:last-child {
    margin-bottom: 40px;
  }
</style>
