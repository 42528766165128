<template>
  <ion-page>
    <ion-header translucent>
      <ion-toolbar>
        <ion-buttons>
          <IonBackButton
            text=""
            default-href="/main/home"
          />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding-start ion-padding-end pt-5">
      <div class="mb-16-lg tablet-centered">
        <CompanyDetails :company-details="companyData"/>
      </div>
      <section
        v-if="companyData.description"
        class="mb-4 tablet-centered"
      >
        <h1 class="mb-4">
          {{ $t('companyPage.about') }}
        </h1>
        <p
          v-if="isReadMore"
          class="about"
        > {{ companyData.description }} </p>
        <p
          v-else
          class="about"
        > {{ shortDescription }} </p>
        <p
          v-if="hasReadMore"
          class="fz-12 fw-400 ion-text-right pointer"
          @click="isReadMore = !isReadMore"
        >
          {{ readMoreLabel }}
        </p>
      </section>

      <section
        v-if="companyPopular.length"
        class="tablet-centered"
      >
        <h1 class="mb-4"> {{ $t('companyPage.popular') }} </h1>
        <ion-slides
          :options="{ slidesPerView: 'auto', slideShadows: false}"
          :key="`${companyData.id}-popular`"
        >
          <ion-slide
            v-for="(data, i) in companyPopular"
            :key="`popular-${i}`"
            class="ion-justify-content-start"
          >
            <RecentlySinglePodcast
              :podcast-data="formattedPodcastData(data)"
              stream-user-progress
            />
          </ion-slide>
        </ion-slides>
      </section>

      <section
        v-if="companyReleases.length"
        class="tablet-centered"
      >
        <h1 class="mb-4"> {{ $t('companyPage.justReleases') }} </h1>
        <ion-slides
          :options="{ slidesPerView: 'auto', slideShadows: false}"
          :key="`${companyData.id}-releases`"
        >
          <ion-slide
            v-for="(data, i) in companyReleases"
            :key="`releases-${i}`"
            class="ion-justify-content-start"
          >
            <RecentlySinglePodcast
              :podcast-data="formattedPodcastData(data)"
              stream-user-progress
            />
          </ion-slide>
        </ion-slides>
      </section>

      <section v-if="library.length">
        <h1 class="mb-4 tablet-centered-500"> {{ $t('companyPage.series') }} </h1>
        <div class="tablet-centered-500">
          <SeriesPodcast
            v-for="(course, index) in library"
            :key="`library-${index}`"
            :course-data="course"
            :grid-layout="false"
            @click="$router.push(`/course/${course.id}`)"
          />
        </div>
      </section>


      <ion-infinite-scroll
        @ionInfinite="loadMore($event)"
        :key="`x-${loadMoreDisabled}`"
        :disabled="loadMoreDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="`${$t('commonKeys.loading')}...`"
        />
      </ion-infinite-scroll>

    </ion-content>
  </ion-page>
</template>

<script>
import CompanyDetails from '@/components/common/CompanyDetails.vue';
import SeriesPodcast from "@/components/common/SeriesPodcast";


import { useRoute, useRouter } from 'vue-router';
import useTracking from "@/composables/useTracking";
import { useStore } from 'vuex';
import { computed, ref } from '@vue/reactivity';
import { useIonRouter } from '@ionic/vue';
import { useI18n } from 'vue-i18n/index';


import {
  IonContent,
  IonPage,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonSlides,
  IonSlide,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue';
import {watch} from "@vue/runtime-core";
import RecentlySinglePodcast from "@/components/common/RecentlySinglePodcast";
import {waitTime} from "@/helpers/functions";

export default {
  name: 'Company',
  components: {
    RecentlySinglePodcast,
    CompanyDetails,
    SeriesPodcast,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonContent,
    IonSlides,
    IonSlide,
    IonPage,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  setup(props) {
    const ionRouter = useIonRouter();
    const route = useRoute();
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const { trackThis } = useTracking();

    const fetchAllData = () => {
      store.commit('company/resetState');
      store.dispatch('company/setCompanyDetails', route.params.companyId);
      store.dispatch('company/setCompanyPopular', route.params.companyId);
      store.dispatch('company/setCompanyReleases', route.params.companyId);
      store.dispatch('company/setCompanyLibrary', route.params.companyId);
    };

    fetchAllData();

    const companyData = computed(() => store.getters['company/getCompanyData']);
    const companyPopular = computed(() => store.getters['company/getCompanyPopular']);
    const companyReleases = computed(() => store.getters['company/getCompanyReleases']);

    const openPlayer = (stream, course) => {
      let startAt = course.streams.findIndex(companyStream => companyStream.id === stream.id);
      if (startAt < 0) {
        startAt = 0;
      }
      store.dispatch('player/openPlayer', {
        course,
        company: course.company,
        playlist: course.streams,
        startAt,
      });
    };

    const formattedPodcastData = (data) => Object.assign({}, data, { course_media: data.course.media });

    const library = computed(() => store.getters['company/getCompanyLibrary']);

    const loadMoreDisabled = computed(() => {
      return store.state.company.library.length >= store.state.company.libraryAll.length;
    });


    const loadMore = async (ev) => {
      await waitTime(250);
      await store.commit('company/loadLibraryRecords');
      ev.target.complete();
    };


    const maxLengthText = 234;
    const isReadMore = ref(false);
    const hasReadMore = computed(() => companyData.value?.description?.length > maxLengthText);
    const readMoreLabel = computed(() => isReadMore.value ? t('podcast.readLess') : t('podcast.readMore'));
    const shortDescription = computed(() => companyData.value?.description?.substr(0, maxLengthText) ?? '');

    watch(
      () => route.params.companyId,
      (newValue) => {
        if (newValue) {
          fetchAllData();
        }
      },
      { deep: true },
    );

    return {
      companyData,
      companyPopular,
      loadMoreDisabled,
      formattedPodcastData,
      companyReleases,

      readMoreLabel,
      isReadMore,
      shortDescription,
      hasReadMore,

      library,
      loadMore,
      openPlayer,
    };
  },
};
</script>

<style lang="scss" scoped>
  ion-slide {
    max-width: 310px !important;
    margin-right: 14px;
  }

  .about {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }
</style>
