<template>
  <ion-content class="top-safe-space ion-padding-start ion-padding-end">
    <ion-header class="is-flex ion-align-items-center ion-padding-top">
      <ion-buttons class="ml-auto">
        <ion-button
          class="modal-close-button"
          @click="close"
        >
          <IonIcon :icon="closeIcon" />
        </ion-button>
      </ion-buttons>
    </ion-header>
    <div class="main-content">
      <div class="ion-text-center">
        <div class="is-flex ion-justify-content-center w-100">
          <AppImg
            is-transparent
            img-src="./assets/medicast-logo.png"
            style="height: 100px; width: 100px"
          />
        </div>

        <h2 class="title fw-900 mb-3">Medicast</h2>
        <h3 class="subtitle fw-700">MedischeScholing</h3>
      </div>


      <div class="is-flex is-flex-direction-column ion-align-items-center">
        <div
          v-if="isWeb"
          class="buttons-wrap ion-text-center"
        >
          <h5 class="fw-400 mb-3 ion-text-center">{{ $t('invitationCard.downloadApp') }}</h5>
          <AppImg
            is-transparent
            img-src="./assets/google-play-badge.png"
            style="width: 270px; height: 80px;"
            class="ion-margin-bottom pointer"
            @click.prevent="openAndroidUrl"
          />

          <AppImg
            is-transparent
            img-src="./assets/apple-ios-badge.png"
            class="pointer"
            style="width: 270px; height: 90px;"
            @click.prevent="openAppleUrl"
          />
        </div>
        <div
          v-if="isWeb"
          class="w-100 tablet-centered"
        >
          <ion-button
            class="w-100 ion-margin-top"
            shape="round"
            mode="ios"
            expand="full"
            @click="close"
          >
            {{ $t('commonKeys.continueWithoutLogin') }}
          </ion-button>
        </div>
        <div
          v-if="!isLogged"
          class="is-flex ion-justify-content-center ion-padding-vertical w-100 tablet-centered"
        >
          <ion-button
            router-link="/sign-up"
            fill="outline"
            mode="ios"
            shape="round"
            expand="full"
            class="fw-500 fz-18 uppercase mr-3 w-50"
            @click="routeAnotherPage"
          >
            {{ $t('commonKeys.register') }}
          </ion-button>
          <ion-button
            router-link="/login"
            fill="outline"
            mode="ios"
            shape="round"
            expand="full"
            class="fw-500 fz-18 uppercase ml-3 w-50"
            @click="routeAnotherPage"
          >
            {{ $t('commonKeys.login') }}
          </ion-button>
        </div>
        <ion-button
          mode="ios"
          fill="clear"
          class="uppercase fw-500 fz-12 contact-us"
          @click.prevent="openUrl('https://app.medicast.nl/contact')"
        >
          {{ $t('contact.us') }}
        </ion-button>
      </div>
      <div class="logo-wrap is-flex ion-justify-content-center ion-align-itiems-end">
        <IonImg
          src="./assets/logo.png"
          style="height: 100px"
        />
      </div>

    </div>
  </ion-content>
</template>

<script>
import AppImg from '@/components/common/AppImg.vue';

import { IonContent, IonButton, IonLoading, IonImg, IonCard, IonIcon, modalController, IonHeader, IonButtons } from '@ionic/vue';
import { close as closeIcon } from 'ionicons/icons';
import useInAppBrowser from '@/composables/useInAppBrowser';
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';

export default {
  name: 'PublicPodcastPageModal',
  components: {
    IonContent,
    IonButton,
    IonLoading,
    IonImg,
    IonCard,
    IonIcon,
    IonHeader,
    IonButtons,
    AppImg,
  },
  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
  },
  setup(props) {
    const { openUrl } = useInAppBrowser();
    const store = useStore();

    const isLogged = computed(() => store.getters['authentication/isLogged']);
    const isWeb = computed(() => store.state.app.platform === 'web');

    const openAndroidUrl = () => openUrl(process.env.VUE_APP_ANDROID_STORE);
    const openAppleUrl = () => openUrl(process.env.VUE_APP_IOS_STORE);

    const routeAnotherPage = () => {
      store.dispatch('player/closePlayer');
      props.close();
    };

    return {
      openAndroidUrl,
      openAppleUrl,
      routeAnotherPage,
      isLogged,
      isWeb,

      closeIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 50px;
}
.subtitle {
  font-size: 20px;
  letter-spacing: 2.7px;
  margin-bottom: 20px;
}

.forgot-btn {
  color: var(--white);
  text-decoration: none;
}

.img {
  height: 105px;
}

.logo-wrap {
  margin: 10px 0 20px;
  flex-shrink: 0;
  flex-grow: 1;
}
ion-icon {
  font-size: 24px;
}
.token-text {
  width: 100%;
}
.card-wrap {
  width: 300px;
}
.buttons-wrap {
  width: 270px;
}
</style>

<style>
  .public-modal .modal-wrapper {
    width: 100%;
    height: 100%;
  }

  .public-modal::part(content) {
    width: 100%;
    height: 100%;
  }
</style>
