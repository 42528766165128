<template>
  <ion-page>
    <ion-header
      v-if="isLogged"
      translucent
    >
      <ion-toolbar>
        <ion-buttons>
          <IonBackButton
            text=""
            default-href="/main/home"
          />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <AppHeaderDownloadApp />
    <ion-content>
      <EditButton
        v-if="canEdit"
        :id="courseData.id"
        :url="`courses/${courseData.id}/edit`"
        always-shown
      />
      <div class="tablet-centered ion-padding-horizontal ion-padding-top">
        <section>
          <CompanyDetails
            :company-details="courseData.company"
            :follow-btn-tooltip="!$store.state.favorites.tooltips.tooltip_followed_viewed"
            @click:tooltip:label="$store.dispatch('favorites/setTooltipStorage', { tooltip_followed_viewed: true })"
          />
          <PodcastInfo
            :podcast-info="courseData"
            :like-btn-tooltip="!$store.state.favorites.tooltips.tooltip_favorite_viewed"
            :like-btn-tooltip-text="$t('onboardingTooltips.favoriteCourseBtn')"
            @click:tooltip:label="$store.dispatch('favorites/setTooltipStorage', { tooltip_favorite_viewed: true })"
          />
          <div class="sort-btn-wrap is-flex ion-align-items-center">
            <p>{{ $t('podcast.allEpisodes') }}</p>
          </div>
        </section>

        <PublicPodcastStreamList v-if="!isLogged" />

        <div
          v-else
          class="single-podcasts-wrap tablet-centered"
        >
          <SinglePodcast
            v-for="(stream, i) in courseData.streams"
            :key="`podcast${i}`"
            :podcast-data="stream"
            :course-media="courseData.media"
            @click="openPlayer(stream, courseData)"
          />
        </div>

      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import CompanyDetails from '@/components/common/CompanyDetails.vue';
import SinglePodcast from '@/components/common/SinglePodcast.vue';
import PodcastInfo from '@/components/common/PodcastInfo.vue';
import PublicPodcastPageModal from "@/components/public/PublicPodcastPageModal";
import AppImg from '@/components/common/AppImg.vue';
import EditButton from '@/components/common/EditButton.vue';

import { useRoute, useRouter } from 'vue-router';
import useTracking from "@/composables/useTracking";
import { useStore } from 'vuex';
import { computed, ref } from '@vue/reactivity';
import {  modalController } from '@ionic/vue';

import {
  IonContent,
  IonPage,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonBackButton,
  toastController,
} from '@ionic/vue';
import { onMounted, watch } from "@vue/runtime-core";
import {
  TRACKING_PUBLIC_COURSE_VIEW,
  NOT_FOUNT_REDIRECT_STORAGE_KEY,
  TRACKING_COURSE_VIEW,
  TRACKING_PUBLIC_PODCAST_VIEW,
  TRACKING_MAIL_CONTENT_CLICK,
} from "@/config/constants";
import useAppStorage from "@/composables/useAppStorage";
import AppHeaderDownloadApp from "@/components/common/AppHeaderDownloadApp";
import PublicPodcastStreamList from "@/components/public/PublicPodcastStreamList";
import { checkPlatform } from "@/helpers/functions";
import { useI18n } from 'vue-i18n/index';

export default {
  name: 'PublicPodcastSeries',
  components: {
    PublicPodcastStreamList,
    AppHeaderDownloadApp,
    CompanyDetails,
    SinglePodcast,
    PodcastInfo,
    IonHeader,
    IonIcon,
    IonToolbar,
    IonButtons,
    IonContent,
    IonPage,
    IonButton,
    IonBackButton,
    AppImg,
    EditButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { trackThis } = useTracking();
    const { setKey } = useAppStorage();
    const { t } = useI18n();

    const canEdit = computed(() => {
      const hasPermission = store.getters['authentication/hasPermission']('update_course');
      const isWeb = store.state.app.platform === 'web';
      return hasPermission && isWeb;
    });

    const courseData = computed(() => store.getters['course/getCourseData']);
    const isLogged = computed(() => store.getters['authentication/isLogged']);
    const isMobileApp = computed(() => store.state.app.platform === 'android' || store.state.app.platform === 'ios');

    const followBtnTooltipActive = ref(true);
    const favoriteBtnTooltipActive = ref(true);

    store.dispatch('favorites/importTooltipStorage');

    const openPlayer = (stream, course, tracking, skipAutoplay = false) => {
      let startAt = course.streams.findIndex(companyStream => companyStream.id === stream.id);
      if (startAt < 0) {
        return;
      }
      store.dispatch('player/openPlayer', {
        course,
        company: course.company,
        playlist: course.streams,
        startAt,
        tracking,
        skipAutoplay,
      });
    };

    const fetchPublicData = () => {
      // tracking
      trackThis(TRACKING_PUBLIC_COURSE_VIEW, {}, route.params.courseId);

      store.commit('course/refreshCourseData');
      store.commit('player/setPlayerCollapse', true);
      store.dispatch('course/setPublicCourseData', route.params.courseId)
        .then((course) => {
          const currentStream = course.streams.find(el => el.id === +route.query.s);
          if (route.query.s  && isAudioAvailable(currentStream)) {
            openPlayer({id: Number(route.query.s)}, course, TRACKING_PUBLIC_PODCAST_VIEW, true);
          } else if (route.query.s) {
            noStreamPermissonToast();
          }
        });
    };

    const fetchPrivateData = () => {
      // tracking
      store.state.course.courseTrackingEnabled
        ? trackThis(TRACKING_COURSE_VIEW, {}, route.params.courseId)
        : store.commit('course/setCourseTrackingState', true);

      store.commit('course/refreshCourseData');
      store.commit('player/setPlayerCollapse', true);
      store.dispatch('course/setCourseData', route.params.courseId).then((course) => {
        if (route.query.s) {
          openPlayer({id: Number(route.query.s)}, course);
        }
      });
    };

    const isAudioAvailable = (stream) => {
      const hasAudioFile = stream.media.some(el => el.media_type === 'audio');
      return Boolean(hasAudioFile || stream.embed_url);
    };

    const emailTrackingCheck = () => {
      if (route.query.ui) {
        const meta = {
          url: process.env.VUE_APP_BASE_URL + route.fullPath,
          unique_token: route.query.ui,
        };
        trackThis(TRACKING_MAIL_CONTENT_CLICK, meta);
      }
    };


    const fetchCourseData = () => {
      isLogged.value
        ? fetchPrivateData()
        : fetchPublicData();

      emailTrackingCheck();
    };

    const noStreamPermissonToast = async () => {
      const toast =  await toastController.create({
        message: t('podcast.noPermission'),
        position: 'top',
        color: 'warning',
        buttons: [
          { text: 'X' },
        ],
      });
      return toast.present();
    };


    fetchCourseData();

    watch(
      () => [route.params.courseId, route.query],
      (newValue) => {
        // console.log('watcher trigger', newValue);
        if (newValue[0]) {
          fetchCourseData();
        }
      },
      { deep: true },
    );


    const openPublicDownloadAppModal = async () => {
      const modal = await modalController.create({
        component: PublicPodcastPageModal,
        cssClass: 'public-modal',
      });
      await modal.present();
    };

    onMounted( async () => {
      setKey(NOT_FOUNT_REDIRECT_STORAGE_KEY, route.fullPath);

      if (store.state.app.platform === null) {
        const platform = await checkPlatform();
        store.commit('app/setAppPlatform', platform);
      }

      // if (!isLogged.value && !isMobileApp.value) {
      //   openPublicDownloadAppModal();
      // }
    });


    return {
      followBtnTooltipActive,
      favoriteBtnTooltipActive,
      courseData,
      openPlayer,
      isLogged,
      isMobileApp,
      canEdit,
    };
  },
};
</script>

<style lang="scss" scoped>
.sort-btn-wrap {
  justify-content: space-between;
  margin: 30px 0;
}

.single-podcast-wrap {
  position: relative;

  .no-access-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .podcast-opacity {
    opacity: 0.2;
    cursor: unset;
    pointer-events: none;
  }
}
.single-podcasts-wrap .single-podcast:last-child {
  margin-bottom: 40px;
}

.button-wrap {
  width: 50%;
}
</style>
