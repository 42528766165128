<template>
  <header
    v-if="!isLogged"
    class="header w-100"
  >
    <div class="is-flex ion-padding-horizontal ion-justify-content-around tablet-centered">
      <div class="ion-text-center has-text-white ion-padding-vertical">
        <div class="is-flex ion-justify-content-center">
          <AppImg
            is-transparent
            img-src="./assets/medicast-logo.png"
            style="height: 100px; width: 100px"
          />
        </div>
        <h1 class="title fw-900 mb-1 fz-30">Medicast</h1>
        <h6 class="subtitle fw-700 fz-12">MedischeScholing</h6>
      </div>

      <div
        v-if="isWeb"
        class="ion-padding-vertical ion-padding-horizontal is-flex is-flex-direction-column ion-justify-content-around"
      >
        <div class="is-flex ion-justify-content-end">
          <AppImg
            is-transparent
            img-src="./assets/apple-ios-badge.png"
            class="pointer"
            style="height: 50px; width: 150px; object-fit: fill;"
            img-fit='fill'
            @click.prevent="openAppleUrl"
          />
        </div>

        <div class="is-flex ion-justify-content-start">
          <AppImg
            is-transparent
            img-src="./assets/google-play-badge.png"
            style="height: 50px; width: 150px; object-fit: fill;"
            class="pointer"
            img-fit='fill'
            @click.prevent="openAndroidUrl"
          />
        </div>
      </div>
    </div>

    <div class="is-flex buttons-wrap ion-justify-content-center ion-padding-bottom tablet-centered">
      <div class="button-wrap ion-text-right">
        <ion-button
          fill="outline"
          mode="ios"
          shape="round"
          expand="full"
          class="fw-500 fz-18 uppercase mx-3"
          @click="redirectTo('/sign-up')"
        >
          {{ $t('commonKeys.register') }}
        </ion-button>
      </div>
      <div class="button-wrap">
        <ion-button
          fill="solid"
          mode="ios"
          shape="round"
          expand="full"
          class="fw-500 fz-18 uppercase ion-margin-horizontal mx-3"
          @click="redirectTo('/login')"
        >
          {{ $t('commonKeys.login') }}
        </ion-button>
      </div>
    </div>
  </header>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import useInAppBrowser from "@/composables/useInAppBrowser";

import AppImg from '@/components/common/AppImg.vue';
import { IonButton } from '@ionic/vue';
import {useRouter} from "vue-router";

export default {
  name: "AppHeaderDownloadApp",

  components: {
    AppImg,
    IonButton,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const { openUrl } = useInAppBrowser();

    const openAndroidUrl = () => openUrl(process.env.VUE_APP_ANDROID_STORE);
    const openAppleUrl = () => openUrl(process.env.VUE_APP_IOS_STORE);

    const isLogged = computed(() => store.getters['authentication/isLogged']);
    const isWeb = computed(() => store.state.app.platform === 'web');

    const redirectTo = (path) => {
      store.dispatch('player/closePlayer');
      router.push(path);
    };

    return {
      isWeb,
      isLogged,

      redirectTo,
      openAppleUrl,
      openAndroidUrl,
    };
  },
};
</script>

<style scoped>
  .header {
    background: var(--grey);
  }
  .subtitle {
    letter-spacing: 1.7px;
  }
</style>
